"use client";

import CssBaseline from "@mui/material/CssBaseline";
import {
  ThemeProvider,
  createTheme,
  type ThemeOptions,
} from "@mui/material/styles";
import React from "react";
import { roboto, spectral, tapestry } from "../constants/fonts";
import { NextAppDirEmotionCacheProvider } from "./EmotionCache";

const themeOptions: ThemeOptions = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "scrollbarColor": "#654 #543",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#432",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#543",
            minHeight: 24,
            border: "3px solid #432",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#765",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#765",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#765",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#432",
          },
        },
      },
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: roboto.style.fontFamily,
    h1: {
      fontFamily: tapestry.style.fontFamily,
    },
    h2: {
      fontFamily: tapestry.style.fontFamily,
    },
    h3: {
      fontFamily: tapestry.style.fontFamily,
    },
    h4: {
      fontFamily: tapestry.style.fontFamily,
    },
    h5: {
      fontFamily: spectral.style.fontFamily,
    },
    body1: {
      fontSize: 16,
    },
  },
  palette: {
    background: {
      default: "#765",
      paper: "#654",
    },
    primary: {
      main: "#fd6",
      contrastText: "#111",
    },
    secondary: {
      main: "#432",
      contrastText: "#fd6",
    },
    text: {
      primary: "#fff",
      secondary: "#ccc",
      disabled: "#999",
    },
  },
};

const theme = createTheme(themeOptions);

export default function ThemeRegistry({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <NextAppDirEmotionCacheProvider options={{ key: "mui" }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}
